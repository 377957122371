import { requireSource } from '@/utils';
import { QRCode } from 'antd';
import { observer } from 'mobx-react';
import type ShareCardModel from './model';

const ShareCard = ({ store }: { store: ShareCardModel }) => {
  return (
    <div
      id="ShareCardElement"
      className="w-[375px] h-[812px] overflow-hidden fixed z-[-99] left-[100%] top-[100%]"
      style={{
        background: `no-repeat left top/375px 812px url(${requireSource('/canvas/tools-share-card.png')})`,
      }}
    >
      <div className="px-[45px] mt-[189px] text-[#333] font-600 text-[28px] whitespace-pre-line">{store?.text}</div>
      <div className="flex items-center absolute bottom-[60px] right-[30px]">
        <div className="mr-4 text-white text-right">
          <div className="mb-3 text-14 ">扫描二维码</div>
          <div className="text-12 ">{store?.qrText}</div>
        </div>
        <div className="bg-white">
          <QRCode
            type="svg"
            bordered={false}
            size={77}
            value={store?.wxUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ShareCard);
