import { RenderByPermission } from '@/utils';
import { Button, Card, Image, Space } from 'antd';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import ShareCard from '../components/shareCard';
import MarketingModel from './model';

export default observer(() => {
  const store = useMemo(() => new MarketingModel(), []);
  return (
    <Space
      direction="vertical"
      wrap={true}
      style={{ display: 'flex' }}
    >
      {store.marketingList.map((item, index) => {
        return (
          <Card
            key={`${index.toString()}`}
            title={item.title}
          >
            <Space
              size="middle"
              align="center"
              wrap={true}
            >
              {item.list.map((im, ix) => (
                <div
                  key={`${ix.toString()}`}
                  className="flex items-center bg-[#F7F7F7] px-6 py-4"
                >
                  <div className="flex-[0_0_auto]">
                    <Image
                      style={{ width: 80, height: 80, objectFit: 'cover' }}
                      src="https://pbbfront.pinbobo.cn/wxApp/boquan/images/my/defaultAvatar.png"
                      preview={false}
                    />
                  </div>

                  <div className="px-4 text-nowrap">
                    <p className="text-16 font-600">{im.title}</p>
                    <p>{im.subtitle}</p>
                  </div>
                  <RenderByPermission permissionId={`78_${im.permissionId}`}>
                    <>
                      <Button
                        className="mr-2"
                        type="primary"
                        onClick={im.onClick}
                      >
                        {im.btnText}
                      </Button>
                      {im?.btnTextTwo && (
                        <Button
                          loading={im.btnTextTwoLoading}
                          type="primary"
                          onClick={async () => {
                            try {
                              im.btnTextTwoLoading = true;
                              await im.onclickTwo();
                            } finally {
                              im.btnTextTwoLoading = false;
                            }
                          }}
                        >
                          {im.btnTextTwo}
                        </Button>
                      )}
                    </>
                  </RenderByPermission>
                </div>
              ))}
            </Space>
          </Card>
        );
      })}
      <ShareCard store={store.shareCardStore} />
    </Space>
  );
});
